<template>
  <CHeader with-subheader style="background-color: #FFF !important; color: black !important;"><img src="logotipo-menu.png" style="width: 160px; padding: 5px;" alt="Logo Oscuro">
    <CHeaderNav class=" mr-auto">
    </CHeaderNav>
    <CHeaderNav>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import router from '../router/index'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import account_ws from '../services/account';
import login from '../services/login';

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data () {
    return { 
      has_partners: 0,
      has_owners: 0
    }
  },
  mounted: async function() {
    
  },
  methods: {
    showAccountModal () {
      this.$refs.newAccountModal.storeModal();
    },
    storeAccount (data) {
      this.$refs.partnersDropdown.refresh();
    },
    logout: function(){
      localStorage.removeItem("account");
      localStorage.removeItem("token");

      router.push("login");
    },
  }
}
</script>
