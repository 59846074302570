<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <span style="height: 20px; color: gray">{{user_name}}
        &nbsp;&nbsp;&nbsp;
        <CIcon :height="20" name="cil-cog" /></span>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Mi Cuenta</strong>
    </CDropdownHeader>
    <span v-for="(item, index) in menu">
      <CDropdownItem v-if="!item.is_divider" :href="item.target">
        <CIcon :name="item.icon" /> {{item.label}}
      </CDropdownItem>
      <CDropdownDivider v-if="item.is_divider" />
    </span>
    <CDropdownItem @click="reload">
      <CIcon name="cil-reload" /> Sincronizar
    </CDropdownItem>
    <CDropdownItem v-if="!(is_kam) && today_visits == 'today'" @click="chargeWeek">
      <CIcon name="cil-calendar" /> Visitas de la semana
    </CDropdownItem>
    <CDropdownItem v-if="!(is_kam) && today_visits == 'week'" @click="chargeToday">
      <CIcon name="cil-calendar" /> Visitas del día
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="logout">
      <CIcon name="cil-lock-locked" /> Cerrar Sesión
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import router from '../router/index'
import store from '../store'
import account_ws from '../services/account';
import ws from '../services/general';
import operation_ws from '../services/operation';
import moment from 'moment';

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      name: '',
      user_name: '',
      is_kam: 0,
      menu: [],
      today_visits: "today"
    }
  },
  mounted: async function() {
    let response = await account_ws.getInfo(); 
    
    if(response.type == "success"){
      this.name = response.data.name;
      this.user_name = response.data.first_name+" "+response.data.last_name;
    }

    this.is_kam = localStorage.getItem("is_kam") == "false" ? 0 : 1;   
    this.today_visits = localStorage.getItem("today_visits");     
    // let response2 = await ws.getMenu("header"); 

    // if(response2.type == "success"){
    //   this.menu = response2.data;
    // }
  },
  methods: {
    reload: function(){
      localStorage.removeItem("last_login");

      location.reload();
    },
    chargeWeek: async function(){  
      this.loading();   
      localStorage.removeItem("last_login");
      router.push("/home/week");

      this.today_visits = "week";

      this.loaded();
    },
    chargeToday: async function(){  
      this.loading();   
      localStorage.removeItem("last_login");
      router.push("/home/today");
      
      this.today_visits = "today";

      this.loaded();
    },
    logout: function(){
      localStorage.removeItem("token");
      localStorage.removeItem("last_login");
      store.commit('clear');
      router.push("login");
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>